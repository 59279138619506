jQuery(document).ready(function($) {
    $("[data-button]").click(function() {
        if($(this).hasClass("is-active")) {
            $(this).removeClass("is-active");
            $(this).next().removeClass("is-active");
            return;
        } else {
            $("[data-button]").removeClass("is-active");
            $("[data-button]").next().removeClass("is-active");
            $(this).addClass("is-active");
            $(this).next().addClass("is-active");
        }
    });

    $(document).on('click', function(e) {
        if(!$(e.target).is("[data-button]")) {
            $("[data-button]").removeClass("is-active");
            $("[data-button]").next().removeClass("is-active");
        }
    });

    $("body").on('keyup', function(e) {
        if (e.which === 27) { // detect if it is Esc button
            $("[data-button]").removeClass("is-active");
            $("[data-button]").next().removeClass("is-active");
        }
    });
});
