'use strict';

/**
 * Toggle script that is useful for showing navigation, modals, etc.
 *
 * The following data attributes are supported:
 *
 * data-toggle
 * data-toggle-class
 * data-toggle-body-class
 */

(function () {
	const elements = document.querySelectorAll('[data-toggle]');
	
	if (elements.length === 0) {
		return false;
	}
	
	elements.forEach(element => {
		const targetElement = document.querySelector(element.dataset.toggle);
	
		if (!targetElement) {
			return;
		}
		
		const bodyClass = element.dataset.toggleBodyClass;
		const toggleClass = element.dataset.toggleClass || 'is-toggled';
	
		element.addEventListener('click', () => {
            const isToggled = element.classList.contains(toggleClass);
		    const removeElement = element.dataset.toggleRemove;

            if(removeElement == 'submenu' && isToggled == false) {
                console.log(isToggled);
                let submenuitems = document.querySelectorAll('.header-nav__child');
                submenuitems.forEach(function (elem) {
                    elem.classList.remove('is-active');
                });

                let submenubtns = document.querySelectorAll('.header-nav__btn');
                submenubtns.forEach(function (elem) {
                    elem.classList.remove('is-active');
                });
            }

			targetElement.classList.toggle(toggleClass);
			element.classList.toggle(toggleClass);
			
			element.setAttribute('aria-expanded', isToggled ? 'false' : 'true');
			targetElement.setAttribute('aria-hidden', isToggled ? 'true' : 'false');
			
			if (bodyClass) {
				document.body.classList.toggle(bodyClass);
			}            
		}, false);
	});
}());
