'use strict';

/**
 * Logic for close button in global alert
 *
 */

(function () {
    // Function to make it easier to read cookies
    function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }
    const alert_cookie = getCookie("banner");

	const element = document.getElementById('close-banner');
    const bodyClass = 'has-cta';

	if (element == null) {
		return false;
	}

    // Check cookie and set data attribute
    if (alert_cookie == '0' || alert_cookie == null) {
        document.body.classList.add(bodyClass);
    }

    element.addEventListener('click', () => {
        document.body.classList.remove(bodyClass);
        document.cookie = "banner=1; max-age=2592000; path=/";
    }, false);
}());
