let pricePlans = document.querySelectorAll('.price-item');

// Do this only if the page has a pricing selector
if (pricePlans.length > 0) {

    function getCurrencySymbol(currencyCode) {
        switch (currencyCode) {
            case 'usd':
                return '$';
            case 'eur':
                return '€';
            case 'gbp':
                return '£';
        }
        throw new Error('Unknown currencyCode ' + currencyCode);
    }

    let currencySelector = document.querySelector('#currency-selector');
    let billingIntervalSelectors = document.querySelectorAll('button[name="interval"]');

    currencySelector.addEventListener('change', function () {
        updatePlanCurrenciesAndPrices();
        saveSelectedCurrency(currencySelector);
    });

    let selectedCurrency = localStorage.getItem('selected-currency');
    if (selectedCurrency) {
        currencySelector.value = selectedCurrency;
        updatePlanCurrenciesAndPrices();
    } else {
        fetchDefaultCurrency();
    }

    billingIntervalSelectors.forEach((billingIntervalSelector) => {
        billingIntervalSelector.addEventListener('click', function () {
            billingIntervalSelectors.forEach((singleSelector) => {
                singleSelector.classList.toggle('is-active');
            });
            updatePlanCurrenciesAndPrices();
        });
    });

    function updatePlanCurrencies() {
        const selectedCurrency = document.querySelector('#currency-selector').value
        const currencySymbols = document.querySelectorAll('.pricing-card__currency');
        currencySymbols.forEach((currencySymbol) => {
            currencySymbol.innerHTML = getCurrencySymbol(selectedCurrency);
        });
    }

    function updatePlanPrices() {
        const selectedBillingInterval = getSelectedBillingInterval();
        const selectedCurrency = document.querySelector('#currency-selector').value
        pricePlans.forEach((pricePlan) => {
            const pricesAttribute = pricePlan.getAttribute('data-prices');
            const currencyValue = pricePlan.querySelector('.pricing-card__value');
            if (pricesAttribute && currencyValue) {
                const planPrices = JSON.parse(pricesAttribute);
                currencyValue.innerHTML = planPrices[selectedCurrency][selectedBillingInterval + '-val'];
            }
        });
    }

    function updatePlanCurrenciesAndPrices() {
        updatePlanCurrencies();
        updatePlanPrices();
    }

    function getSelectedBillingInterval() {
        return document.getElementById('monthly').classList.contains('is-active') ? 'monthly' : 'yearly';
    }

    function saveSelectedCurrency(currencySelector) {
        localStorage.setItem('selected-currency', currencySelector.value);
    }

    function fetchDefaultCurrency() {
        var request = new XMLHttpRequest();
        request.open('GET', 'https://ipinfo.io/country');
        request.onload = function () {
            if (request.status >= 200 && request.status < 400) {
                const country = request.responseText.trim();
                const euroCountries = [
                    'AD', 'AT', 'BE', 'CY', 'DE', 'EE', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LV',
                    'LT', 'LU', 'MT', 'MC', 'NL', 'PT', 'SM', 'SK', 'SI', 'VA'];

                let detectedCurrency;
                if (euroCountries.includes(country)) {
                    // Europe = EUR
                    detectedCurrency = 'eur';

                } else if (country === 'GB') {
                    // UK = GBP
                    detectedCurrency = 'gbp';

                } else {
                    // Rest of the world = USD
                    detectedCurrency = 'usd';
                }

                document.getElementById('currency-selector').value = detectedCurrency;
                updatePlanCurrenciesAndPrices();
                saveSelectedCurrency(currencySelector);
            }
        };
        request.send();
    }
}

document.addEventListener("DOMContentLoaded", function() {

	// var pricingBtn = document.querySelectorAll('.pricing-btn');
	// if (pricingBtn) {
	// 	pricingBtn.forEach(function(item) {
	// 		item.addEventListener('click', function() {

    //             var elems = document.querySelectorAll(".pricing-btn.is-active");
    //             elems.forEach(function(el) {
    //                 el.classList.remove("is-active");
    //             });

    //             this.classList.add('is-active');
	// 		});
	// 	});
	// }

});



