jQuery(document).ready(function($) {
    $(window).scroll(function(){
        if ($(window).scrollTop() >= 5000) {
            $('.cta-bar').addClass('cta-bar--fixed');
        }
        else {
            $('.cta-bar').removeClass('cta-bar--fixed');
        }
    });
});
