// Vanilla Javascript tooltip.
// https://github.com/zoltantothcom/vanilla-js-tooltip
Tooltip = function (t) {
    function e(t, e, o, a) {
        var i, s, r = t.getBoundingClientRect();
        switch (o) {
            case"left":
                i = parseInt(r.left) - n - e.offsetWidth, parseInt(r.left) - e.offsetWidth < 0 && (i = n);
                break;
            case"right":
                i = r.right + n, parseInt(r.right) + e.offsetWidth > document.documentElement.clientWidth && (i = document.documentElement.clientWidth - e.offsetWidth - n);
                break;
            default:
            case"center":
                i = parseInt(r.left) + (t.offsetWidth - e.offsetWidth) / 2
        }
        switch (a) {
            case"center":
                s = (parseInt(r.top) + parseInt(r.bottom)) / 2 - e.offsetHeight / 2;
                break;
            case"bottom":
                s = parseInt(r.bottom) + n;
                break;
            default:
            case"top":
                s = parseInt(r.top) - e.offsetHeight - n
        }
        i = 0 > i ? parseInt(r.left) : i, s = 0 > s ? parseInt(r.bottom) + n : s, e.style.left = i + "px", e.style.top = s + pageYOffset + "px"
    }

    var o = t.theme || "dark", a = t.delay || 0, n = t.distance || 10;
    document.body.addEventListener("mouseover", function (t) {
        if (t.target.hasAttribute("data-tooltip")) {
            var a = document.createElement("div");
            a.className = "b-tooltip b-tooltip-" + o, a.innerHTML = t.target.getAttribute("data-tooltip"), document.body.appendChild(a);
            var n = t.target.getAttribute("data-position") || "center top", i = n.split(" ")[0];
            posVertical = n.split(" ")[1], e(t.target, a, i, posVertical)
        }
    }), document.body.addEventListener("mouseout", function (t) {
        t.target.hasAttribute("data-tooltip") && setTimeout(function () {
            document.body.removeChild(document.querySelector(".b-tooltip"))
        }, a)
    })
};


//
// Enable tooltips
//
var tooltip = new Tooltip({
    distance: 0, // Specifies the distance in pixels from trigger to tooltip.
    delay: 0     // Specifies how long the tooltip remains visible (in ms) after the mouse leaves the trigger.
});
