//
// Play GIF on click
//
let playableGifs = document.querySelectorAll('.play-gif');
if (playableGifs.length > 0) {
    [].forEach.call(playableGifs, function (playableGif) {
        let thisImg = playableGif.getElementsByTagName('img')[0],
            thisJpg = thisImg.getAttribute('data-jpg'),
            thisGif = thisImg.getAttribute('data-gif');
        preloadGif = document.createElement('img');

        preloadGif.classList.add('d-none');
        preloadGif.setAttribute('src', thisGif);
        playableGif.appendChild(preloadGif);
        playableGif.addEventListener('click', function () {

            if (playableGif.classList.contains('play')) {
                thisImg.setAttribute('src', thisJpg);
            } else {
                thisImg.setAttribute('src', thisGif);
            }
            playableGif.classList.toggle('play');
        });
    });
}
